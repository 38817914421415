h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 700;
	color: #000;
	line-height: 1.2;
	margin: 0 0 @spacing*0.6;

	span{
		color: @color-primary;
	}

	&.preheader{
		margin-bottom: @gutter*2;
		color: @color-primary;
		font-size: 1.6rem;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
		

		span{
			position: relative;
			display: inline-block;
			&:after{
				content: '';
				top: 50%;
				left: 100%;
				position: absolute;
				width: 100%;
				border-bottom: 0.1rem solid #000;
				margin: 0 2rem;
			}
		}

		&.-right{
			text-align: right;
			span{
				&:after{
					left: auto;
					right: 100%;
				}
			}

			@media all and (max-width: 400px){
				text-align: left;
				position: absolute;
				top: 105rem;
				span {
					&:after{
						left: 100%;
						right: 0%;
					}
				}
			}
		}
	}
}


h1, .h1 {
	font-size: 2.4rem;
}

h2, .h2 {
	font-size: 1.8rem;
}

h3, .h3 {
	font-size: 1.5rem;
}

h4, .h4 {
	font-size: 1.4rem;
}

h5, .h5 {
	font-size: 1.4rem;
}

h6, .h6 {
	font-size: 1.4rem;
}



@media all and(min-width: 480px){
	h1, .h1{
		font-size: calc(~"2.4rem + (4 - 2.4)*(100vw - 48rem)/(96 - 48)");
	}
	h2, .h2{
		font-size: calc(~"2rem + (3.6 - 2)*(100vw - 48rem)/(96 - 48)");
	}
	h3, .h3{
		font-size: calc(~"1.5rem + (2 - 1.5)*(100vw - 48rem)/(96 - 48)");
	}
	h4, .h4{
		font-size: calc(~"1.4rem + (1.6 - 1.4)*(100vw - 48rem)/(96 - 48)");
	}
}


@media all and(min-width: 960px){
	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		margin: 0 0 @spacing;

		+.postheader{
			margin-top: -@spacing;
			margin-bottom: @spacing;
		}
	}
	h1, .h1{
		font-size: 4rem;
	}
	h2, .h2{
		font-size: 3.6rem;
	}
	h3, .h3{
		font-size: 2rem;
	}
	h4, .h4{
		font-size: 1.6rem;
	}
}
