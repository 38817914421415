.flex-grid{
	background: blue;
	&__col{
	
		background-color: #fff;
	}
	&__col:last-child{
		background-color: #f9f9f9;
	}

	&__content{
		position: relative;
		width: 100%;
		padding: @gutter @gutter/2;
		box-sizing: border-box;
	}

	@media all and(min-width: 840px){
		display: flex;
		flex-wrap: wrap;
		position: relative;
		overflow:hidden;
		//width: 100vw;

		&__col{
			position: relative;
			box-sizing: border-box;
			width: 50%;
		}

		&__col--57{
			width: (790/1400)*100%;
		}

		&__col--43{
			width: (610/1400)*100%;
		}
	}
	@media all and(min-width: 1340px){
		
		&__content{
			padding-top: @gutter*2;
			padding-bottom: @gutter*2;
		}

		&__col--57{
			&:first-child{
				.flex-grid__content{
					padding-left: @gutter;
					left: calc(~"(100vw - 1340px) / 2");
					width: calc(~"100% - (100vw - 1340px) / 2");
				}
			}
		}

		&__col--43{
			&:last-child{
				.flex-grid__content{
					padding-left: @gutter*1.5;
					max-width: 51rem;
				}
			}
		}
	}
}