.burger {
    position: absolute;
    left: 0;
    right: @gutter;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
    height: 1.6rem;
    cursor: pointer;
    &:before,
    &:after {
        content: '';
        position: absolute;
        top: -1.2rem;
        left: -0.5rem;
        width: 4rem;
        height: 4rem;
        background: #eee;
        border-radius: 50%;
        transform: scale(0, 0);
        transition: transform 200ms;
    }
    &:hover {
        @media (min-width: 960px) {
            &:before {
                transform: scale(1, 1);
            }
        }
    }
    >div {
        z-index: 2;
        width: 100%;
        height: 0.4rem;
        position: absolute;
        background: currentColor;
        transform-origin: 50% 50%;
        transition: transform 300ms 0ms, top 300ms 300ms, bottom 300ms 300ms, opacity 300ms 300ms;
        &:first-child {
            top: 0;
        }
        &:nth-child(2) {
            bottom: 0;
        }
    }
    .main-nav-open & {
        >div {
            transition: transform 300ms 300ms, top 300ms, bottom 300ms, opacity 300ms 0ms;
            &:first-child {
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }
            &:last-child {
                bottom: 50%;
                transform: translateY(50%) rotate(-45deg);
            }
        }
    }
}