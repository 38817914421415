.award-content h1 {
    color: @color-primary;
}
.award-list {
    display: flex;
    flex-flow: row wrap;
    padding-left: 3rem;
    &__item {
        margin-bottom: @gutter;
        &:hover {
            filter: grayscale(10%);
        }
        transition: filter .4s;
    }
    .figure {
        margin-bottom: 0;
        padding: 5px;
        &__thumbnail {
            width: 100%;
            background-origin: padding-box;
            background-size: contain;
            background-repeat: no-repeat;
            height: auto;
            .thumbnail__overlay {
                background-color: rgba(0, 0, 0, 0);
                position: relative;
                width: calc(~"100%");
                height: 190px;
                &:hover {
                    background-color: rgba(100, 100, 100, 0.03);
                }
                transition: background-color .4s;
            }
        }
        &__document-link {
            display: block;
            width: 40px;
            height: 40px;
            font-size: 30px;
            position: relative;
            bottom: 40px;
            left: 5px;
            border-radius: 50%;

            opacity: 0;
            background-color: rgb(247, 247, 247);
            transition: opacity .4s;
            &.show {
                opacity: 1;
            }

            color: #2ecc71 !important;
            &:active {
                color: #2ecc71;
            }
            &:visited {
                color: #2ecc71;
            }
            &:hover {
                color: #2ecc71 + 30 !important;
            }
        }
        &__icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%)
                       translateY(-50%);
        }
    }

    &__text {
        text-align: center;
        font-style: italic;
    }

    @media all and (max-width: 600px) {
        &__item {
            margin-bottom: @gutter;
            width: calc(~"100%") !important;
        }
    }
    @media (max-width: 770px) {
        p {
            width: 100%;
        }
    }
    @media (min-width: 768px) and (max-width: 960px) {
        &__item {
            margin-top: 3rem;
        }
    }
    @media all and (max-width: 960px) {
        &__item {
            margin-right: 3rem;
            width: calc(~"50% - 4rem");
        }
        &:nth-child(2n + 0) {
            margin-right: 0;
        }
    }
    @media all and (min-width: 960px) {
        &__item {
            margin-right: 3rem;
            width: calc(~"(100 / 3) * 1% - 4rem");
        }
        &:nth-child(3n + 0) {
            margin-right: 0;
        }
    }
}
