.form {
	select,
	textarea,
	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="color"],
	.uneditable-input {
		display: inline-block;
		box-sizing: border-box;

		margin: 0;
		padding: @spacing/3 @gutter/3;

		width: 100%;
		height: 4.5rem;

		font-size: 1.6rem;
		line-height: 2.6;
		color: @font-color;

		outline-color: @color-primary;


		border: 1px solid #d5d5d5;

		background: #fff ;
	}
}



.form select {
	box-shadow: 0 1px 1px 0 rgba(0,0,0,.05);
}

.form {
	margin-bottom: @spacing;

	div.error{
		font-size: 1.4rem;
		color: #c70505;
	}

}

label {
	display: block;
	
	margin: 0 0 @spacing / 3 0;

	font-weight: 700;
	color: #666;
	text-transform: uppercase;

	span {
		display: inline-block;

		margin: 0 0 0 1rem;

		font-weight: normal;
		font-size: 1.1rem;
	}
}


.wf-active{
	input[type="file"]{
		width: 1px; 
		height: 1px;
		overflow: hidden;
		position: absolute;
		+label{
			padding: 0 1.5rem;
			background: @color-blue;
			display: inline-block;
			line-height: 34/16;
			color: #fff;
			cursor: pointer;
		}
	}
}

.FormManager{
	max-width: 73rem;
	margin-bottom: @gutter;
	>h2{
		display: none;
	}
}

.mailchimp-registration{
	max-width: 50rem;
	//margin-bottom: @spacing;

	.mailchimp-thank-you{
		padding: @gutter*2 0;
		font-size: 3rem;
		font-weight: 800;
		text-align: center;
		display: none;
	}

	#mc_embed_signup{
		
	}

	&.form-sent{
		.mailchimp-thank-you{
			display: block;
		}

		#mc_embed_signup{
			display: none;
		}
	}

	&.sending{
		.form{
			opacity: 0.5;
		}
	}

	&__title{
		margin-top: @spacing;
		color: #fff;
		font-size: 1.6rem;
		font-weight: 400;
	}
	.form{
		display: flex;
		margin-bottom: 0.5rem;

		button{
			margin-left: @gutter/2;
			min-width: 17rem;
		}
	}

	.message-box{
		font-size: 1.4rem;
		
	}

	@media all and(min-width: 960px){
		&__title{
			margin-top: 0;
		}
	}

	&.-with-cats{
		margin-bottom: @gutter;
		max-width: 100%;
		.form{
			display: block;

			button{
				margin-left: 0;
			}
		}

		.mailchimp-registration__email{
			margin-bottom: @gutter;
			input{
				width: 100%;
				margin-bottom: 1rem;
			}
		}

		.mailchimp-registration__groups{
			margin-bottom: @gutter;

			p{
				margin-top: 1rem;
				font-size: 1.4rem;
			}
		}
	}
}