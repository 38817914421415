// link.less
a:not(.button):not(.menu-toggler) {
    color: #3b5da5;
    text-decoration: none;
    -webkit-transition: all ease-in-out 150ms;
    -moz-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms;
    &:hover {
        @media (min-width: 960px) {
            text-decoration: none;
            color: darken(#3b5da5, 20%);
        }
    }
    &.-large {
        font-size: 1.8rem;
        font-weight: 700;
    }
    &.-small {
        font-size: 1.4rem;
    }
}