// text.less

.form {
	textarea {
		min-height: 6em;

		resize: vertical;

		&.disabled,
		&[disabled] {
			resize: none;
		}
	}
}