.mobile-sidebar{
	position: fixed;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	padding: @gutter 0;
	padding-bottom: 5rem;
	z-index: 30;
	box-sizing: border-box;	
	width: calc(~"100% - 6rem");
	max-width: 24rem;
	top: 4.9rem;
	bottom: 0;
	right: 0;

	background: @color-primary;

	transform: translateX(100%);
	transition: transform 500ms;

	.sidebar-open &{
		transform: translateX(0)!important;
	}


	.mobnav >ul.disill-navigation{
		padding: 0;
		margin: 0;
		list-style: none;
		
		&:first-child{
			>li:last-child >ul li:last-child >a{
				border-bottom: none;
			}
		}

		>li{
			a{
				display: block;
				padding: @gutter/2 @gutter;
				color:#fff;
			
				border-bottom: 0.1rem solid rgba(255,255,255,0.2);

				&:active, &:focus, &.selected{
					text-decoration: none;
					color: #fff;
				}
			}

			&.selected{
				>a{
					color: #f90;
					//background: #fff;
				}
			}

			&:first-child{
				a{
					border-top: 0.1rem solid rgba(255,255,255,0.2);
				}
			}

			&:last-child{
				>a{
					//border-bottom: 0;
				}
			}

			>ul {
				list-style: none;
				padding-left: 0;

				li{
					a{

						padding-left: @gutter+@spacing;
					}

					&:first-child{
						a{
							border-top: none;
						}
					}

					&:last-child{
						a{
							border-bottom: 0.1rem solid rgba(255,255,255,0.2);
						}
					}
				}
			}
		}
	}

	@media all and (min-width: 960px){
		display: none;
	}
}

