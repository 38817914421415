// dropdown.less


.custom-select{
	width: 100%;
	position: relative;
	display: block;

	&.focus{
		>div{		
		background: linear-gradient(to bottom, #ffffff 0%,#ddd 100%);
		}
	}

	>div{
		position: relative;
		box-sizing: border-box;
		height: 4.5rem;
		max-width: 100%;
		padding: 0 @spacing;
		padding-right: 4.5rem;
		line-height: 2.6;
		border: 0.1rem solid #e7e7e7;
		background: #f3f3f3;
		background: linear-gradient(to bottom, #ffffff 0%,#f3f3f3 100%);

		.title{
			display: inline-block;
			position: relative;
			padding-right: 0.35rem;
			width: calc(~"100% - 1rem");
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			color: inherit;
		}

		.trigger{
			width: 4.5rem;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			
			&:before{
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				margin-top: -0.4rem;
				margin-left: -0.8rem;
				height: 0; 
				border: 0.8rem solid transparent;
				border-top-color: #595858;
			}

			&:after{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 0.1rem;
				height: 100%;
				background: #c3c3c3;
			}	
		}
	}

	&.select--md{
		>div{
			height: 3.6rem;
			line-height: 3.6rem;
		}
	}

	&.select--lg{ 
		>div{
			height: 4rem;
			line-height: 4rem;
		}
	}

	select{
		position: absolute;
		top: 0;
		left: 0;
		height: 4.5rem;
		width: 100%;
		appearance: none;

		opacity: 0;
		z-index: 1;

		&:focus{
			outline: 1px solid red;
		}

		&.select--lg{
			height: 4rem;
		}

		option{
			line-height: 2;
		}
	}

	&.dropdown--md{
		>div{
			height: 4.2rem;
			line-height: 4.2rem;

			.trigger{
				height: 4.2rem;
			}
		}

		select{
			height: 4.2rem;
		}
	}

	&.dropdown--block{
		display: block;
		max-width: 100%;
	}
}

