.main-wrap{
	width: 100%;
	margin: 0 auto;
}

.main-content{
	position: relative;
	min-height: 50vh;
	transition: opacity 500ms;
	max-width: 160rem;
	margin: 0 auto;
}



.offset_left{
	margin-left: -@gutter;
	padding-left: @gutter;
}

@media all and (min-width: 1250px){
	.offset_left{
		margin-left: calc(~"((100vw + 8rem - " @max-width ~") / 2) * -1");
		padding-left: calc(~"((100vw + 8rem - " @max-width ~") / 2)");
	}
}


@media all and (min-width: 1600px){
	.offset_left{
		margin-left: calc(~"((1600px + 8rem - " @max-width ~") / 2) * -1");
		padding-left: calc(~"((1600px + 8rem - " @max-width ~") / 2)");
	}
}