.article-index {
    .article-swiper-arrow-left {
        left: 0px;
    }

    .article-swiper-arrow-right {
        right: 0px;
    }

    .article-swiper-arrow-left, .article-swiper-arrow-right {
        display: none;
        height: 25px;
        top: 290px;
        @media all and(max-width: 600px) {
            display: block;
        }
    }

    &__item {
        margin-bottom: @gutter;
        position: relative;
        @media all and (max-width: 600px) {
            box-sizing: border-box;
            padding: 35px;
        }
        .figure {
            background-color: @color-gray-light;
            background-size: cover;

            &:before {
                content: '';
                position: relative;
                display: block;
                height: 0;
                padding-bottom: (250/370)*100%;
            }
        }

        h1 {
            font-size: 2.2rem;
            margin-bottom: 0.5rem;;
            a {
                color: #000;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .more {
            font-size: 1.4rem;
        }

        time {
            font-size: 1.2rem;
            color: @color-gray-dark;
        }
    }

    @media all and (min-width: 600px) {
        display: flex;
        flex-wrap: wrap;
        &__item{
            margin-right: 2rem;
            width: calc(~"(100/3)*1% - (4rem / 3)");

            &:nth-child(3n + 0){
                margin-right: 0;
            }
        }
    }
    @media all and (min-width: 960px) {
        &__item{
            margin-right: 3rem;
            width: calc(~"(100/3)*1% - 2rem");
        }
    }
}

.main-article-index {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr;
    >article {
        width: calc(100% - 2rem);
        &:nth-child(3n + 1) {
            -ms-grid-column: 1;
        }
        &:nth-child(3n + 2) {
            -ms-grid-column: 2;
        }
        &:nth-child(3n + 3) {
            -ms-grid-column: 3;
        }
        &:nth-child(n + 4) {
            -ms-grid-row: 1;
        }
        &:nth-child(n + 7) {
            -ms-grid-row: 1;
        }
        &:nth-child(n + 10) {
            -ms-grid-row: 2;
        }
        &:nth-child(n + 13) {
            -ms-grid-row: 3;
        }
        &:nth-child(n + 16) {
            -ms-grid-row: 4;
        }
        &:nth-child(n + 19) {
            -ms-grid-row: 5;
        }
    }

    @media all and (max-width: 600px) {
        display: block;
        >article {
            width: 100%;
            padding: 20px;
        }
    }

    @media all and (max-width: 400px) {
        >article {
            padding: 0;
        }
    }
}
