// config.less

// Sizes
@min-width: 32rem; // Page min width
@max-width: 125rem; // Page max width
@base-size: 1.6em;

@spacing: 2rem; // Text and inline spacing
@gutter: 4.0rem; // Gutter and block level spacing
@radius: 0.01rem; // Default border radius

@headerMobileHeight: 5rem;
@headerHeight: 8rem;

// Typography
@font-family: 'Poppins', sans-serif;
@font-size: convert(@base-size,rem);
@font-color: #666;
@font-color-gray: @color-gray-dark;
@line-height: 1.5;

// Grid
@grid-count: 5;
@grid-min-break: 320;
@grid-max-break: 1200;
@grid-break-interval: 120;


// Grayscale

@color-gray-darker: #050505;
@color-gray-dark: #666;//using
@color-gray: #999;//using
@color-gray-light: #ccc;//using
@color-font-gray: #7a7a7a;


// Base colors
@color-primary: #006bbc;


@color-blue: #006bbc;
@color-blue-light: #87a1b1;


// Background colors
@background-primary: #fff;
@background-secondary: #f7f7f7;

@link-primary: @color-primary;



// Border colors
@border-primary: #e5e5e5;

// Buttons & Forms
@button-border-radius: @radius;
@button-font-size-normal: @font-size;

