.verkefni-swiper{
//  position: relative;
//  max-height: 50rem;
margin: 0;
overflow: visible;

    .swiper-pagination{
        position: relative;
        z-index: 3;
        right: 0;
        bottom: 0;
        top: 3rem;
        display: flex;
        @media all and(max-width: 770px){
            top: -3rem;
        }
        
        span{
            display: block;
        }
        
        .swiper-pagination-bullet{
            cursor: pointer;
			font-weight: 700;
			color: @color-gray-light;
            margin-bottom: 3rem;
            @media all and(max-width: 1040px){
                margin-right: 1rem;
            }
			&.swiper-pagination-bullet-active{
                color: @color-primary;
			}
			&:last-child{
                margin-bottom: 0;
            }
            &:first-child{
                letter-spacing: 1px;
			}
		}
    }
    
    @media all and(min-width: 1048px){
        .swiper-pagination{
            flex-direction: column;
            position: absolute;
            justify-content: center;
        }
    }
}

.verkefni-single{
    position: relative;
    background-color: #fff;
    height: auto;
    >div{
        position: relative;
        padding: @gutter*1.5 0;
        width: calc(~"100vw - ((100vw - 125rem)/2)");
        @media all and(max-width: 770px){
            width: 102vw;
        }
    }
    &__bg{
        //position: absolute;
        margin-bottom: @gutter;
        left: 0;
        top: 0;
        height: auto;
        background-size: cover;
        width: 100%;
        transform: translateY(-6rem);
        transition: transform 1000ms cubic-bezier(0.39, 0.575, 0.565, 1);

        &:before{
            content: '';
            display: block;
            padding-bottom: 56%;
            height: 0;
            position: relative;
        }
    }
    
    &__content{
        max-width: 125rem;
        margin: 0 auto;
        z-index: 1;
        display: flex;
        align-items: center;
        //    justify-content: flex-end;
    }
    
    &__text{
        padding-right: @gutter;
        padding-left: @gutter;
        width: 100%;
        h1,h2,p,a{
            max-width: 35rem;
        }
        @media all and(max-width: 770px){
            h1 {
                margin-bottom: 4rem!important;
            }
        }
    }
    
    .figure{
        display: none;
        width: calc(~"50% - " @gutter*2);
        max-width: 56rem;
        border: 0.8rem solid @color-primary;
        margin-right: @gutter*2;
        margin-bottom: 0;
        transform: translateY(6rem);
        transform-origin: 50% 50%;
        transition: transform 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
        background-size: cover;
    }
    
    &.swiper-slide-active.visible{
        .verkefni-single__bg, .figure{
            transform: translateX(0);
        }
    }
    
    @media all and(min-width: 768px){
        &__bg{
            height: 100%;
            width: 40%;
            position: absolute;
            margin-bottom: 0;
        }

        &__text{
            width: 30%;
            padding-left: 0;
        }

        .figure{
            margin-left: @gutter;
            display: block;

            &:before{
                content: '';
                position: relative;
                height: 0;
                padding-bottom: 56%;
                display: block;
            }
        }
    }
}


.verkefni-list{
    &__item{
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: @gutter;
        overflow: hidden;
        position: relative;
    }

    &__figure{
        position: relative;
        z-index: 1;
        background: #eee;
        
        &:before{
            content: '';
            height: 0;
            display: block;
            position: relative;
            padding-bottom: 56%;
        }
        
        >div{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
        }
    }
    
    &__text{
        z-index: 2;
        p{
            color: #666;
            line-height: 2;
        }
        a{
            text-transform: uppercase;
            color: @color-gray-darker;
            font-weight: 700;
            font-size: 1.2rem;
        }
    }
    
    
    @media all and(min-width: 768px){
        &__item{
            flex-direction: row;
            margin-bottom: 0;
            transform: translateY(20rem);
            transition: transform 1500ms ;
            
            &.visible{
                transform: translateY(0);
                position: relative;
                margin: 0 0 8rem 0;
                .verkefni-list__text{
                    transform: translateY(0);
                }
                
                .figure >div{
                    transform: scale(1,1);
                }
            }
            
            &.-type1{
                .verkefni-list__figure:before{
                    padding-bottom: (525/600)*100%
                }
            }
            &.-type2{
                flex-direction: row-reverse;
                
                .verkefni-list__text{
                    padding-right: 0;
                    padding-left: 8rem;
                    width: (100/3)*1%;
                }
                
                .verkefni-list__figure{
                    width: ((100/3)*2)*1%;
                    &:before{
                        padding-bottom: (520/800)*100%
                    }
                }
            }
            &.-type3{
                position: relative;
                .verkefni-list__text{
                    position: absolute;
                    width: calc(~"(100/3) * 1%");
                    padding: 3rem;
                    box-sizing: border-box;
                    left: 50%;
                    height: 100%;
                    transform: translateX(-50%) translateY(20rem);
                    background: #fff;
                }
                
                &.visible{
                    .verkefni-list__text{
                        transform: translateX(-50%) translateY(0);
                    }
                    
                }
                
                .verkefni-list__figure{
                    width: 100%;
                    &:before{
                        padding-bottom: (520/1180)*100%
                    }
                }
            }
            
        }
        
        &__text{
            box-sizing: border-box;
            padding: 6rem 8rem 6rem 0;
            width: 50%;
            transform: translateY(20rem);
            transition: transform 1500ms;
            
            h2{
                margin-bottom: @gutter * 1.5;
            }
        }
        
        &__figure{
            margin-bottom: 0;
            overflow:hidden;
            >div{
                transform: scale(1.1,1.1);
                display: block;
                transform-origin: 50% 50%;
                transition: transform 1000ms 500ms;
            }
            width: 50%;
        }
    
    }

    @media all and(min-width: 1250px){

    }
}

.verkefni-article-index {
    position: relative;
    top: -12rem; 
    @media all and(max-width: 770px){
        top: -5rem;
        article {
            position: relative;
        }

    }
}