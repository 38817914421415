// blockquote.less

blockquote {
	font-weight: 500;
	color: #757575;

	&:before{
		content: '„';
		margin-right: 0.5rem;
		font-size: 1.2em;
	}

	&:after{
		content: '“';
		margin-left: 0.5rem;
		font-size: 1.2em;
	}
}
