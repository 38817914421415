.grid {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
	*zoom: 1;

	> .col {
		display: block;
		box-sizing: border-box;

		float: left;
		min-height: 1px;
	}

	.spanX(@index, @total) when (@index > 0) {
		> .col.s@{index}of@{total} { 
			.span(@index, @total);
		}
		
		.spanX(@index - 1, @total);
	}

	.spanX(0, @total) {}

	.span(@columns, @total) {
		@final: (100 / @total) * @columns;
		width: ~"@{final}%";
	}
	.grids(@index, @total) when (@index > 0){
		.spanX (@index, @total);
		.grids (@index - 1, @total - 1);
	}

	.grids(0, @total) {}

	.grids(@grid-count, @grid-count);

	&.reverse {
		> .col {
			float: right;
		}
	}

	&.gutter {
		margin-left: -@gutter/2;
		margin-right: -@gutter/2;
		
		> .col {
			padding-left: @gutter/2;
			padding-right: @gutter/2;
		}
	}

	&.gutter-tight {
		margin-left: -@gutter/4;
		margin-right: -@gutter/4;

		> .col {
			padding-left: @gutter/4;
			padding-right: @gutter/4;
		}
	}

	&.gutter-wide {
		margin-left: -@gutter;
		margin-right: -@gutter;

		> .col {
			padding-left: @gutter;
			padding-right: @gutter;
		}
	}

	&.debug {
		
		> .col {
			background-color: fade(#000,25%);

			&:nth-child(2n) {
				background-color: fade(#000,15%);
			}
		}
	}
}
