// button.less

@button-color: @color-primary;

.button {
	display: inline-block;
	box-sizing: border-box;

	overflow: hidden;
	
	margin: 0 0 0.3em;
	padding: 0 @gutter;

	height: 4.5rem;

	min-width: 6rem;
	max-width: 100%;

	vertical-align: bottom;

	line-height: 2.75;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
	text-transform: uppercase;
	font-size: 1.8rem;
	color: #fff;

	cursor: pointer;
	user-select: none;

	border: none;
	background: @button-color;

	transition: background 500ms;

	span[class*='icon-']{
		font-size: 2.6rem;
		margin-left: 1.5rem;
		vertical-align: -0.3rem;
	}

	&:hover {
		text-decoration: none;
		background: @button-color + 10;
	}

	&:active,
	&:focus {
		background: @button-color + 20;
		outline: none;
	}

	&--disabled,
	&:disabled {
		pointer-events: none;
		opacity: 0.5;

		background: #c9c9c9;

		color: #fff;
	}

	
	

	&.-blue{
		background: @color-blue;
		color: #fff;

		&:hover{
			background: @color-blue + 20;
		}

		&:active,
		&:focus {
			background: @color-blue + 30;
			outline: none;
		}
	}

	&.-lg{
		height: 5.5rem;
		line-height: 3.3;
		padding: 0 @gutter*1.3;

		span[class*='icon-']{
			font-size: 3rem;
			vertical-align: -0.5rem;
		}
	}
}

// Button groups
.button-group {
	margin: 0 0 @spacing;
}
