.html-text{
	&--box{
		padding: @gutter/2;
		margin-bottom: @spacing;
		border: 0.1rem solid @border-primary;
	}

	&--blueborder{
		border-left: 0.5rem solid @color-blue;
	}

	@media all and(min-width: 720px){
		&--box{
			padding: @gutter;
		}
	}
}