// list.less

ul, ol, dl {
	margin: 0 0 @spacing;

	ul, ol, dl {
		margin-bottom: 0;
	}
}

ul {
	padding: 0 0 0 1em;
}

.unorded-list{
	margin: 0 0 @spacing;
	padding: 0;
	list-style: none;
	li{
		padding-left: @gutter;
		margin-bottom: 1rem;
		position: relative;
		&:before{
			position: absolute;
			left: 0;
			top: 1rem;
			display: block;
			content: '';
			height: 0.6rem;
			width: 0.6rem;
			border-radius: 50%;
			background: @color-blue;
		}
	}
}

.html-text, .article{
	ul{
		.unorded-list;
	}
}

ol {
	padding: 0 0 0 1.5em;
}

@media all and(min-width: 960px){
	.unorded-list{
		margin-bottom: @spacing*2;
	}
}

