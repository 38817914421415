@-ms-viewport { width: device-width; }
@-o-viewport { width: device-width; }
@viewport { width: device-width; }

html {
	overflow-x: hidden;
	overflow-y: scroll;
	font-size: 62.5%;

	background: @background-primary;
}

body {
	margin: 0;
	padding-top: @headerMobileHeight;
	line-height: @line-height;
	font-family: @font-family;
	font-size: @base-size;
	color: @font-color;
	-webkit-font-smoothing: antialiased;

	background: @background-primary;

	opacity: 0;
	transition: opacity 500ms;
	
	.font-loaded &, .wf-active &{
		opacity: 1;
	}

	@media all and(min-width: 960px){
		padding-top: @headerHeight;
	}
	
	@media all and(min-width: 1750px){
		padding-top: 0;
	}
	

}

