.statistic{
    margin-bottom: @spacing;
    >div:first-child{
        color: @color-primary;
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.5;
    }
    >div:last-child{
        text-transform: uppercase;
        font-size: 1.2rem;
        color: @color-gray;
        font-weight: 700;
    }
    @media all and(min-width: 960px){
        margin-bottom: @spacing*2;
    }
}