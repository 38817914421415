.box{
	background: #fff;
	padding: @gutter/2;
	border: 0.1rem solid @border-primary;
	box-shadow: 0 0.3rem 0 0 rgba(237,237,237,1);
	margin-bottom: @spacing;

	
	@media all and(min-width: 840px){
		padding: @gutter;
		margin-bottom: @gutter;
	}
}