
.figure{
	margin: 0;
	margin-bottom: @spacing;
	padding: 0;

	&--full-width{
		img{
			width: 100%;
		}
	}

	&--no-margin-bottom{
		margin-bottom: 0;
	}

	&--right{
		text-align: right;
	}

	&--center{
		text-align: center;
	}

	@media all and(min-width: 960px){
		margin-bottom: @spacing*2;
	}
}

.figure.with-caption {
	position: relative;
	img{
		display: block;
		width: 100%;
	}

	figcaption{
		position: absolute;
		bottom: 0;
		right: 0;
		padding: @spacing;
		font-size: 1.4rem;
		line-height: 1em;
		color: #fff;
		text-transform: uppercase;
		background: rgba(0,0,0,0.5);
	}
}

.aspect{
	height: 0;
	position: relative;
	padding-bottom: (3/4)*100%;

	iframe{
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}

	&--r16-9{
		padding-bottom: (9/16)*100%;
	}
}

img{
	max-width: 100%;
}

.image .figure{
	filter: grayscale(100%);
	background: #eee;

	img{
		opacity: 0.1;
		transition: opacity 500ms;
		display: block;
	}

	&.image-loaded{
		opacity: 1;
		filter: none;
		img{
			opacity: 1;
		}
	}
}