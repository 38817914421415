// checkradio.less
.form{
	&--radio{
		input{
			display: none;

			&:checked + label{
				&:after{
					opacity: 1;
					background: #4c4c4c;
				}
			}
		}

		&--inline{
			label{
				display: inline-block;
				margin-right: 1rem;
			}
		}

		label{
			position: relative;
			color: @font-color;
			
			font-size: 1.5rem;
			font-weight: 600;
			text-transform: none;

			padding-left: 3.5rem; 

			margin-bottom: 1rem;

			span{
				margin: 0;
				color: #4c4c4c;
			}

			&:before{
				content: '';
				left: 0;
				top: 0;
				position: absolute;
				width: 1.8rem;
				height: 1.8rem;
				background: #f2f2f2;

				border-radius: 100%;
				border: 0.1rem solid @border-primary;
			}

			&:after{
				content: '';
				position: absolute;
				width: 1rem;
				height: 1rem;
				left: 0.5rem;
				top: 0.5rem;
				background: #f2f2f2;
				border-radius: 1rem;

				opacity: 0;
			}

			&:hover{
				&:after{
					opacity: 0.1;
					background: #000;
				}
			}

			+div{
				padding-left: 3.5rem;
				font-size: 1.4rem;
			}
		}
	}

	&--checkbox{
		label{
			position: relative;
			padding-left: 3rem;
			font-weight: 300;
			font-family: @font-family;
			font-size: 1.6rem;
			font-weight: 500;
			line-height: 1.6;
			color: @font-color;
			text-transform: none;

			&:before{
				display: block;
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 2rem;
				height: 2rem;
				border: 0.1rem solid #e7e7e7;
				box-shadow: inset 0 4px 8px -4px rgba(0,0,0,0.3);
			}

			&:after{
				content: "\e90a";
				position: absolute;
				left: 0.5rem;
				top: 0.3rem;
				font-family: 'endurmenntun-icon';
				color: #000;
				line-height: 1.4;
				font-size: 1.4rem;
				opacity: 0;
			}

			&:hover{
				&:after{
					opacity: 0.1;
				}
			}
		}
		input{
			clip: rect(1px, 1px, 1px, 1px); 
			height: 1px; 
			width: 1px; 
			overflow: hidden; 
			position: absolute !important;


			&:checked + label, &:checked + .error +label, &:checked + input + label{
				&:after{
					opacity: 1;
					color: @font-color;
				}
			}
		}
	}
}