.paging{
	display: flex;
	justify-content: center;
	border-top: 0.1rem solid @border-primary;
	padding-top: @gutter;
	a{
		margin-right: 1rem;
		padding: 0 1.5rem;
		border: 0.1rem solid @border-primary;
		color: @color-primary;
		display: block;
		font-weight: 900;
		font-size: 1.4rem;
		line-height: 28/14;
		transition: color 400ms, background 400ms;

		&:hover, &:active{
			background: @color-primary;
			color: #fff;
		}

		&.disabled{
			color: @color-gray;
			pointer-events: none;	
		}

		&:first-child{
			margin-right: 4rem;
		}

		&:nth-last-child(2){
			margin: 0;
		}

		&:last-child{
			margin-left: 4rem;
		}
	}
}