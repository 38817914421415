.banner{
	position: relative;
	width: 100%;
	height: 40rem;
	overflow: hidden;
	display: block;
	max-width: 190rem;


	>div{
		height: 100%; 
		position: relative;
		width: 100%;
	}

	&__more{
		position: relative;
		text-transform: uppercase;
		font-weight: 700;
		color: #000!important;
		font-size: 1.4rem;
	}
	
	
	&__content{
		position: relative;
		z-index: 3;
		height: 100%;
	}

	&__background{
		z-index: 0;
		position: absolute;
		
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		
		// max-width: 50%;
		width: 100%;
		height: (800/960)*100%;
		max-height: 80rem;
		top: 0;
		bottom: 0;
		right: 0;
		filter: grayscale(100);
		opacity: 0.4;
		
		&:before{
			content: '';
			display: block;
			height: 0;
		}

		&.-loaded{
			opacity: 1;
			filter: none;
		}

		.swiper-slide-active &{
			transform: translateY(0);
		}
		
	}

	&.-full{
		overflow: hidden;
		max-height: 70rem;
		.banner__background{
			width: 100%;
			height: 100%;
			transform: none;
		}
	}




	&__text-wrapper{
		position: absolute;
		max-width: @max-width - 8rem;
		box-sizing: border-box;

		width: 100%;
		//height: 100%;
		//bottom: 0;
		
		top: 50%;
		transform: translateY(-50%);

		z-index: 10;

		left: 0;
		right: 0;
		
		margin: auto;
		
		//opacity: 0;
		transition: all 500ms 800ms;
		
	}

	// New Small Image Format
	&__text-image-wrapper{
		position: absolute;
		max-width: @max-width - 8rem;
		box-sizing: border-box;

		width: 100%;
		//height: 100%;
		//bottom: 0;
		
		top: 50%;
		transform: translateY(-50%);

		z-index: 10;

		left: 0;
		right: 0;
		
		margin: auto;
		
		//opacity: 0;
		transition: all 500ms 800ms;
		
	}
	
	
	&__text-wrapper-inner{
		padding: 1rem;
		box-sizing: border-box;
		// position: absolute;
		// left: 0;
		width: 80%;
		z-index: 1;	
		background: rgba(255,255,255,0.9);
		
		&.-has-link{
			padding-right: 6rem;
			
			&:hover{
				//	opacity: 0.1;
				
			}
		}
	}

	// New Small Image Format
	&__text-image-wrapper-inner{
		padding: 1rem;
		box-sizing: border-box;
		// position: absolute;
		// left: 0;
		width: 80%;
		z-index: 1;	
		background: rgba(255,255,255,1);
		
		&.-has-link{
			padding-right: 6rem;
			
			&:hover{
				//	opacity: 0.1;
				
			}
		}
	}
	
	
	&__preheading{
		font-weight: 700;
		font-size: 1.4rem;
		color: @color-primary;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		margin-bottom: @gutter/2;
		span{
			position: relative;
			display: inline-block;
			&:after{
				content: '';
				top: 50%;
				left: 100%;
				position: absolute;
				width: 100%;
				border-bottom: 0.1rem solid #000;
				margin: 0 2rem;
			}
		}
	}
	
	&__heading{
		margin-bottom: @spacing/2;
		font-size: 2.4rem;
		line-height: 1.1em;
		text-transform: uppercase;
	}

	&__text{
		p{
			line-height: 1.4;
		}
	}
	
	
	@media only screen and(min-width: 768px){
		height: 60rem;
		//height: 100%;
		
		&__background{
			transform: translateY(20%);
			//transition: transform 700ms 400ms, opacity 700ms 400ms;
			transition: all 1400ms 400ms;
			width: 50%;
			
			.swiper-slide-active &{
				transform: translateY(0);
			}
		}
		
		&__preheading{
			font-size: 1.4rem;
		}
		
		
		&__text{
			p{
				color: @color-gray-dark;
				line-height: 2;
			}
		}

		&__text-wrapper{
			top: auto;
			bottom: 0;
			height: 100%;
			top: auto;
			transform: none;
			
		}
		
		&__text-wrapper-inner{
			position: absolute;
			padding-left: 0px;
			width: 70%;
			max-width: 90rem;
			bottom: 0;
			background: transparent;
			h1{
				max-width: 74%;
				font-size: 3rem;
				margin-bottom: @gutter;
				color: #000;
			}
			.banner__text{
				width: 100%;
				display: flex;
				>div{
					width: 40%;
				}
			}
		}

		// NEW Format for text image styles
		&__text-image-wrapper{
			top: auto;
			bottom: 0;
			height: 100%;
			top: auto;
			transform: none;
			
		}

		
		&__text-image-wrapper-inner{
			position: absolute;
			padding-left: @gutter;
			width: 70%;
			max-width: 90rem;
			bottom: 0;
			background: transparent;
			.banner__text{
				width: 100%;
				display: flex;
				>div{
					width: 40%;
				}

				.banner__text-image{

					margin-top: 45%;
					margin-left: 45%;
					width: 55%;
					background-repeat: no-repeat;
					background-size: cover;
					border: 0.8rem solid @color-primary;
					transform: translateY(-22%);
					opacity: 0;
					//transition: transform 700ms 500ms, opacity 700ms 500ms;
					transition: all 1000ms;
					filter: grayscale(100);
				
					&:before{
						content: '';
						height: 0;
						display: block;
						padding-bottom: (330/480)*100%;
					}

					.swiper-slide-active &{
						&.-loaded{
							filter: none;
							transform: translateY(0);
							opacity: 1;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (min-width: 1024px){
		&__text-wrapper-inner{
			top: auto; 
			bottom: 0;

			h1{
				font-size: 5.5rem;
			}
		}

		&__text-image-wrapper-inner{
			top: auto; 
			bottom: 0;

			h1{
				font-size: 5.5rem;
			}
		}
	}
		
	@media only screen and (min-width: 1400px){
		height: 85rem;

		&__text-wrapper-inner{
			h1{
				font-size: 6.6rem;
			}
		}

		&__content{
				position: absolute;
				height: 100%;
				width: 100%;
				padding-bottom: 0;
			}

			&__background{
				position: absolute;
			}
		}
}


.banner-swiper{
	position: relative;
	width: 100%;
	margin-bottom: @gutter + 10rem;
	.swiper-pagination{
		position: absolute;
		bottom: 1rem;
		left: 0;
		right: 0;
		width: 100%;
		max-width: 125rem;
		padding: 0 @gutter;
		box-sizing: border-box;
		margin: 0 auto;

		.swiper-pagination-bullet{
			cursor: pointer;
			font-weight: 700;
			color: @color-gray-light;
			margin-right: 1rem;
			&.swiper-pagination-bullet-active{
				color: @color-primary;
			}
			&:last-child{
				margin-right: 0;
			}
		}
	}

	@media all and(max-width: 768px){
		margin-bottom: @gutter;
	}
}

@media all and(max-width: 1400px){
	.banner {
		&__text-wrapper-inner {
			padding-left: 4rem;
		}
	}
}

@media all and(max-width: 768px){
	.text-image-wrapper-2 {
		display: none!important;
	}
	.banner {
		&__text-wrapper-inner {
			padding-left: 1rem;
		}
	}
}

@media all and(max-width: 480px){
	.banner {
		height: 50rem;
		margin-bottom: 6rem;

		&__text-wrapper {
			top: 67%;
		}
		
		&__text-image-wrapper {
			top: 73.5%;
		}

		&__text-image-wrapper-inner {
			height: 30rem;
		}
	}

	.banner-swiper {
		.swiper-pagination {
			top: 96%;
		}
	}


}

@media all and(max-width: 414px){
	.banner {
		&__text-wrapper-inner {
			padding-top: 3rem;
		}

		&__text-wrapper{
			top: 61%;
		}

		&__background {
			height: 65%;
		}
	}

	.banner-swiper {
		.swiper-pagination {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			flex-direction: column;
			position: absolute;
			-webkit-box-pack: center;
			justify-content: center;
			display: flex;
			top: 24rem;
			left: 72.5%;
			margin-bottom: 1rem;
			width: 0px;
			.swiper-pagination-bullet{
				margin-bottom: 1rem;
			}

			.swiper-pagination-bullet:first-child{
				letter-spacing: 1.5px;
			}

			.swiper-pagination-bullet:last-child {
				margin-right: 1rem;
			}
		}

	}

	.banner-mobile {
		max-height: 70vw!important;
		margin-bottom: 2rem!important;
	}
}

