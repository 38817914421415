// table.less


// Basic table
table.table {
	width: 100%;
	margin-bottom: @spacing;
	border: 1px solid @border-primary;

	td, th {
		padding: 10px;
		text-align: left;
		border-bottom: 1px solid @border-primary;
	}
}


// Responsive table wrapper
.table-wrap {
	position: relative;
	overflow: auto;
	margin-bottom: @spacing;
	max-width: 100%;

	table {
		margin-bottom: 0;
	}
}