// footer.less

.main-footer {
	color:@color-gray;
	background: #000;
	position: relative;

	.logo {
		display: block;
		max-width: 240px;
		position: relative;
		margin-bottom: @gutter/2;
	}

    .award-logo {
        transition: none !important;
        max-width: 280px;
    }

	.limit {
		padding-top: @gutter*3;
		padding-bottom: @gutter*3;
	}

	a {
		color: #999!important;
		&:hover {
			color: @color-primary!important;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

    @media all and (max-width: 500px) {
        .award-logo {
            display: none;
        }
    }

    @media all and (max-width: 770px) {
        .award-logo {
            max-width: 200px !important;
        }
    }

    @media all and (max-width: 1800px) {
        .award-logo {
            float: right;
            top: -2 * @gutter;
            max-width: 240px;
        }
    }

	@media all and (min-width: 1800px) {
		.logo {
			position: absolute;
			left: @gutter;
		}
        .award-logo {
            left: unset;
            top: 2 * @gutter;
			right: @gutter;
        }
	}
}
