// text.less

p {
	margin: 0 0 @spacing;
	font-size: 1.4rem;
	line-height: 32/14;

	&.-large{
		font-weight: 700;
		font-size: 1.6rem;
	}

	&.-small{
		font-size: 1.2rem;
	}

	@media all and(min-width: 960px){
		margin: 0 0 @spacing*2;
	}

	@media all and(max-width: 770px) {
		width: 75vw;
	}

	@media all and(max-width: 414px){
		line-height: 1.8;
	}
}