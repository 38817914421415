@keyframes ld-spin-fast {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        animation-timing-function: cubic-bezier(.55,.055,.675,.19)
    }

    50% {
        -webkit-transform: rotate(900deg);
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    to {
        -webkit-transform: rotate(1800deg);
        transform: rotate(1800deg)
    }
}

.loader{
    position: relative;
    display: block;
    padding-top: 5rem;
    opacity: 0;
    transition: opacity 300ms;

    &__text{
        text-align: center;
    }
    
    &__ring{
        position: absolute;
        left: 50%;
        margin-left: -2rem;
        top: 0;
        height: 4rem; 
        width: 4rem;
        transform-origin: 50% 50%;
        color: @color-primary;
        
        &:after{
            content: '';
            height: 100%; 
            width: 100%;
            display: block;
            border: 5px solid currentColor;
            border-top-color: transparent;
            border-radius: 50%;
            box-sizing: border-box;
        }
        
    }
    
    &.loading {
        opacity: 1;
        .loader__ring{
            animation: ld-spin-fast 1s infinite;
        }
    }
}
