.segment{
	&.-condensed{
		.limit{
			max-width: 83rem;
		}
	}

	&.-border-bottom{
		border-bottom: 0.1rem solid @border-primary;
	}

	&.-color-one{
		background: @color-primary;
	}

	&.-color-two{
		background: @background-secondary;
	}
}


.limit {
	margin-left: auto;
	margin-right: auto;
	max-width: @max-width;
	box-sizing: border-box;

	&.-extra-padding-top{
		padding-top: @gutter*2 ;
	}

	&.-limit-padding-top{
		padding-top: @gutter/2;
	}

	&.-extra-padding-bottom{
		padding-bottom: @gutter*2 ;
	}

	&.-limit-padding-bottom{
		padding-bottom: @gutter/2;
	}

	&.-no-padding-top{
		padding-top: 0;
	}

	&.-no-padding-bottom{
		padding-bottom: 0;
	}
}


.padside{
	padding: 0 @gutter*0.75;
}

.padded{
	padding: @gutter @gutter*0.75 @gutter * 0.2;
}
.-extra-side{
	padding: @gutter @gutter*0.75 @gutter*0.2;
}




@media only screen and (min-width: 768px){
	.padded{
		padding: @gutter @gutter @gutter * 0.2;
	}
	.-extra-side{
		padding: @gutter*2 @gutter*2 @gutter*1.2;
	}
}

@media only screen and (min-width: 1024px){
	&.offset_top{
		margin-top: -14rem;
		position: relative;
		z-index: 3;
	
		.limit{
			background: #fff;
		}
	}
	
	.limit{
		&.-extra-padding-top{
			padding-top: @gutter*3;
		}

		&.-limit-padding-top{
			padding-top: @gutter;
		}

		&.-extra-padding-bottom{
			padding-bottom: @gutter*3;
		}

		&.-limit-padding-bottom{
			padding-bottom: @gutter;
		}
	}

	.padded{
		padding: @gutter*2 @gutter @gutter*1.2;
	}

	.-padside{
		padding: 0 @gutter;
	}

	.-extra-side{
		padding: @gutter*2 @gutter*7.8 @gutter*1.2;
	}
}


