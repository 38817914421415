// helpers.less

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.spacing-bottom {
	margin-bottom: @spacing;
}

.gutter-top {
	margin-top: @gutter;
}

.gutter-bottom {
	margin-bottom: @gutter;
}

.gutter-left {
	margin-left: @gutter;
}

.gutter-right {
	margin-right: @gutter;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-gray{
	color: @color-font-gray;
}

.text-blue{
	color: @color-blue;
}

.text-blue-light{
	color: @color-blue-light;
}

.text-white{
	color: #fff;
}


.screen-reader-text { 
   clip: rect(1px, 1px, 1px, 1px); 
   height: 1px; 
   width: 1px; 
   overflow: hidden; 
   position: absolute !important;
}

.only-print{
	display: none;
}