.staff-single{
    margin-bottom: @spacing;
    h2{
        margin-bottom: 0.5rem;
        font-size: 1.8rem;
    }
    &__title{
        color: @color-primary;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 700;
    }

    &__tel, &__email{
        font-size: 1.4rem;
    }

    .figure{
        position: relative;
        background: @color-gray-light;
        img{
            width: 100%;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 300ms;
            &.-loaded{
                opacity: 1;
            }
        }

        &:before{
            content: '';
            height: 0;
            display: block;
            padding-bottom: (315/300) *100%;
        }
    }
}


.staff-filter{
    position: relative;
    .loader{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
    }
    &__header{
        margin-bottom: @spacing;
        .preheader{
            margin-bottom: 1rem;
        }
    }

    ul{
        list-style: none;
        margin: 0; 
        padding: 0;
        li{
            display: inline-block;
            cursor: pointer;
            color: @color-gray;
        }
    }

    .locations{
        li{
            font-size: 1.4rem;
            &.selected{
                color: @color-primary;
            }
            &:first-child{
                margin-right: 1rem;
                &:after{
                    content: '/';
                    color: @color-gray;
                    padding-left: 1.3rem;
                }
            }

            &:nth-child(2){
                margin-right: 1rem;
                &:after{
                    content: '/';
                    color: @color-gray;
                    padding-left: 1.3rem;
                }
            }
        }
    }

    .departments{
        margin-bottom: @spacing;
        li{
            color: @color-gray-light;
            text-transform: uppercase;
            margin-right: 1rem;
            font-weight: 700;
            &:last-child{
                margin-right: 0;
            }
            &.selected{
                color: @color-primary;
            }
        }

        input {
            float: right;

            padding: 2.5px;

            border: solid 1px @color-gray-light;
            border-radius: 0.1px;
            color: @color-gray;

            font-size: 1.2rem!important;
            &:focus {
                border-color: @color-primary;
                outline: none;
            }
        }
    }

    .staff{
        min-height: 20rem;
    }

    @media all and (max-width: 920px){
        .departments input {
            float: none;
            width: 20%;
            margin-right: 80%;
            margin-top: 20px;
       
        }
    }

    @media all and (max-width: 596px){
        .departments input {
            width: 40%;
            margin-right: 60%;
        }
    }


    @media all and(min-width: 480px){
        &__header{
            display: flex;
            justify-content: space-between;
        }   
        .staff{
            display: flex;
            flex-wrap: wrap;
            .staff-single{
                margin-right: 1rem;
                width: calc(~"50% - 0.5rem");

                &:nth-child(2n + 0){
                    margin-right: 0;
                }
            }
        }
    }
    @media all and(min-width: 768px){
        &__header{
            margin-bottom: @gutter;
        }   

        .departments{
            margin-bottom: @gutter;
            li{
                margin-right: 2rem;
            }
        }

        .staff{
            min-height: 30rem; 
            .staff-single{
                margin-right: @gutter;
                width: calc(~"25% - " (@gutter*3)/4);

                &:nth-child(2n + 0){
                    margin-right: @gutter;
                }
                &:nth-child(4n + 0){
                    margin-right: 0;
                }
            }
        }
    }
}


@media all and(max-width: 480px){
    .staff-figure {
        display: none;
        animation: test 200ms ease-in 10ms forwards;
        opacity: 0;
    }
}


@keyframes test {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}