// navigation.less
.main-nav {
    position: fixed;
    z-index: 100;
    top: -4000px;
    visibility: hidden;
    left: 0;
    transition: top 0ms 700ms;
    &--display {
        visibility: visible;
        width: 100%;
        height: 100vh;
        @media (min-width: 960px) {
            width: auto;
            height: auto;
        }
    }
    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #000;
        @media (min-width: 960px) {
            left: 3.6rem;
            top: 2.5rem;
            right: auto;
            bottom: auto;
            width: 6000px;
            height: 6000px;
            border-radius: 50%;
            transform: translate3d(-50%, -50%, 0) scale(0.01);
            background: #000;
            transition: transform 600ms cubic-bezier(.645, .045, .355, 1), opacity 100ms 500ms;
            opacity: 0;
        }
    }
    &__inner {
        position: relative;
        padding: 10rem 5rem;
        opacity: 0;
        transition: opacity 0 0;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                position: relative;
                margin-bottom: 0.5rem;
                display: block;
                &.selected {
                    a {
                        color: @color-primary!important;
                    }
                }
                a {
                    padding-left: 0;
                    display: block;
                    font-size: 2.2rem;
                    color: #fff!important;
                    transform: translateX(0);
                    transition: padding 300ms;
                }
                &:before {
                    position: absolute;
                    display: block;
                    top: 50%;
                    margin-top: -1px;
                    border-bottom: 2px solid #fff;
                    height: 2px;
                    width: 0;
                    content: '';
                    transition: width 300ms;
                }
                &:hover {
                    @media (min-width: 960px) {
                        &:before {
                            width: 30px;
                        }
                        a {
                            color: #fff;
                            padding-left: 40px;
                        }
                    }
                }
            }
        }
    }
    .menu-toggler {
        position: absolute;
        z-index: 101;
        left: 2rem;
        top: 2.5rem;
        padding-left: 4rem;
        color: #fff;
        cursor: pointer;
        .burger {
            &:before {
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }
    .main-nav-open & {
        top: 0;
        transition: top 0ms 0ms;
        &__inner {
            transition: opacity 200ms 200ms;
            opacity: 1;
        }
        &__bg {
            opacity: 1;
            @media (min-width: 960px) {
                transition: transform 600ms cubic-bezier(.645, .045, .355, 1), opacity 100ms 0ms;
                transform: translate3d(-50%, -50%, 0) scale(1);
            }
        }
    }
    @media all and(min-width: 768px) {
        .menu-toggler {
            left: @gutter;
            top: @gutter;
        }
    }
    @media all and(min-width: 600px) {
        &__inner {
            padding-left: 10rem;
            ul {
                li {
                    a {
                        font-size: 4rem;
                    }
                }
            }
        }
    }
}