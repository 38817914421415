// header.less
.main-header {
    position: fixed;
    height: @headerMobileHeight;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 20;
    transform: translateY(0);
    transition: transform 300ms;
    background: #fff;
    .sidefixed & {
        box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, .1);
    }
    .limit {
        position: relative;
        height: 5rem;
        max-width: 100%;
    }
    .logo {
        position: absolute;
        right: @gutter/2;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 16rem;
        img {
            width: 100%;
        }
    }
    .menu-toggler {
        position: absolute;
        z-index: 101;
        left: @gutter/2;
        padding-left: 4rem;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 600;
        text-transform: uppercase;
        color: #000;
        font-size: 1.4rem;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        /* for remove highlight */
        * {
            -webkit-tap-highlight-color: transparent;
            /* for remove highlight */
        }
        &:hover {
            @media (min-width: 960px) {
                color: #888 !important;
            }
        }
    }
    @media all and(min-width: 768px) {
        height: @headerHeight;
        .limit {
            height: @headerHeight;
        }
        .menu-toggler {
            left: @gutter;
        }
        .logo {
            right: @gutter;
            width: 20rem;
        }
    }
    @media all and(min-width: 960px) {
        .logo {
            width: 28rem;
        }
    }
    @media all and(min-width: 1750px) {
        box-shadow: none;
        position: relative;
        transform: none;
        .fixer {
            transition: all 700ms;
        }
        .sidefixed & {
            .fixer {
                height: 100%;
                width: 5rem;
                position: fixed;
                top: 0;
                &.-left {
                    left: -10rem;
                    transition: left 700ms;
                    .menu-toggler {
                        left: 1rem;
                        top: 7rem;
                        transform: rotate(90deg);
                        .burger {
                            transform: rotate(-90deg);
                            +div {
                                position: relative;
                                top: 10px;
                            }
                        }
                    }
                }
                &.-right {
                    right: -10rem;
                    transition: right 700ms;
                    .logo {
                        left: -14rem;
                        right: auto;
                        top: 14rem;
                        transform: rotate(90deg);
                        transform-origin: 50% 50%;
                    }
                }
            }
        }
        .sidefixed-visible & {
            .fixer {
                &.-left {
                    left: 0;
                }
                &.-right {
                    right: 0;
                }
            }
        }
    }
}